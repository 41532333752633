<template>
  <div
    :class="value ? 'cwp-hamburger--active' : ''"
    class="cwp-hamburger"
    tabindex="0"
    @click="$emit('input', !value)"
    @keyup.enter="$emit('input', !value)"
  >
    <div class="cwp-hamburger__bar" />
    <div class="cwp-hamburger__bar" />
    <div class="cwp-hamburger__bar" />
  </div>
</template>

<script>
export default {
  name: 'CwpHamburger',
  props: {
    value: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
}
</script>

<style lang="scss">
.cwp-hamburger {
  height: 32px;
  width: 24px;
  position: relative;
  margin: auto;
  cursor: pointer;

  &__bar {
    position: absolute;
    height: 2px;
    width: 24px;
    display: block;
    margin: 8px auto;
    background-color: var(--header-text-color);
    border-radius: 2px;

    &:nth-of-type(1) {
      top: 0;
      transition: top 200ms $easeInOutQuad 200ms, transform 200ms $easeOutQuad 100ms;
    }
    &:nth-of-type(2) {
      top: 8px;
      transition: 200ms $easeInOutQuad 200ms;
    }
    &:nth-of-type(3) {
      top: 16px;
      transition: top 200ms $easeInOutQuad 200ms, transform 200ms $easeOutQuad 100ms;
    }
  }
  &--active &__bar {
    &:nth-of-type(1) {
      top: 8px;
      transform: rotate(45deg);
      transition: top 200ms $easeInOutQuad 100ms, transform 200ms $easeOutQuad 300ms;
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      top: 8px;
      transform: rotate(-45deg);
      transition: top 200ms $easeInOutQuad 100ms, transform 200ms $easeOutQuad 300ms;
    }
  }
}
</style>
