import Vue from 'vue'
import Vuex from 'vuex'
import settings from './modules/settings.js'
import site from './modules/site.js'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    settings,
    site,
  },
  strict: debug,
})
