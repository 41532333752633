import Vue from 'vue'
import imageHandler from './revium/imageHandler.js'

const reviumPlugins = {
  install() {
    Vue.prototype.$revium = {
      imageHandler: (image, options) => {
        return imageHandler(image, options)
      },
    }
  },
}

Vue.use(reviumPlugins)
