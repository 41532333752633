<template>
  <router-link v-slot="{ navigate, href: rlHref }" :to="to">
    <component
      :is="tag"
      tabindex="0"
      role="button"
      class="cwp-link"
      :class="classes"
      :target="target"
      :aria-label="label"
      :href="external || attachment || isButtonTag ? to : href || rlHref"
      :download="download || attachment"
      v-on="onEvent"
      @click="
        ev => {
          if (download || attachment) {
            $fbq.track('PDF Download')
          }
          if (external || attachment || href || disabled || deactivated || isButtonTag) return
          navigate(ev)
        }
      "
    >
      <slot />
    </component>
  </router-link>
</template>

<script>
export default {
  name: 'CwpLink',
  props: {
    to: {
      type: [String, Object],
      required: false,
      default: '',
    },
    href: {
      type: String,
      required: false,
      default: '',
    },
    target: {
      type: String,
      required: false,
      default: '',
    },
    button: {
      type: Boolean,
      default: false,
      required: false,
    },
    tag: {
      type: String,
      default: 'a',
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    wrapper: {
      type: Boolean,
      default: false,
      required: false,
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    deactivated: {
      type: Boolean,
      default: false,
      required: false,
    },
    download: {
      type: Boolean,
      default: undefined,
      required: false,
    },
  },
  data() {
    return {
      onEvent: {
        mouseenter: ev => this.handleEvent('mouseenter', ev),
        mouseleave: ev => this.handleEvent('mouseleave', ev),
        focus: ev => this.handleEvent('focus', ev),
        blur: ev => this.handleEvent('blur', ev),
        click: ev => this.handleEvent('click', ev),
        'keyup.enter': ev => this.handleEvent('click', ev),
      },
    }
  },
  computed: {
    classes() {
      return {
        'cwp-link--button': this.button,
        'cwp-link--wrapper': this.wrapper,
        'cwp-link--link': !this.button && !this.wrapper,
        'cwp-link--loading': this.loading,
        'cwp-link--disabled': this.disabled,
        'cwp-link--deactivated': this.deactivated,
      }
    },
    external() {
      return !!(
        this.to &&
        (this.to.startsWith('http:') ||
          this.to.startsWith('https:') ||
          this.to.startsWith('tel:') ||
          this.to.startsWith('mailto:') ||
          this.to.startsWith('#'))
      )
    },
    attachment() {
      return !!(this.to && (this.to.includes('/getattachment/') || this.to.includes('/cwpmedia/')))
    },
    isButtonTag() {
      return this.tag === 'button'
    },
  },
  methods: {
    handleEvent(type, ev) {
      if (!this.disabled && !this.deactivated) {
        this.$emit(type, ev)
      }
    },
  },
}
</script>

<style lang="scss">
.cwp-link {
  cursor: pointer;
  transition: background 300ms $easeOutQuad, border-color 300ms $easeOutQuad;
  font-size: inherit;
  color: currentColor;

  &--link {
    &:hover {
      opacity: 0.8;
    }
  }

  &--button {
    @include button;
    @include button-swatch;
  }

  &--loading {
    opacity: 0.64;
    cursor: not-allowed;
    pointer-events: none;
    padding-right: 32px;
    position: relative;
    &:after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      right: -3px;
      transform: translate(-50%, -50%);
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg enable-background='new 0 0 40 40' version='1.1' viewBox='0 0 40 40' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath color='white' fill='currentColor' d='m20.201 5.169c-8.254 0-14.946 6.692-14.946 14.946 0 8.255 6.692 14.946 14.946 14.946s14.946-6.691 14.946-14.946c-1e-3 -8.254-6.692-14.946-14.946-14.946zm0 26.58c-6.425 0-11.634-5.208-11.634-11.634 0-6.425 5.209-11.634 11.634-11.634s11.633 5.209 11.633 11.634c0 6.426-5.208 11.634-11.633 11.634z' opacity='.2'/%3E%3Cpath transform='rotate(334.914 20 20)' color='white' fill='currentColor' d='m26.013 10.047 1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012v3.312c2.119 0 4.1 0.576 5.812 1.566z'%3E%3CanimateTransform attributeName='transform' attributeType='xml' dur='0.6s' from='0 20 20' repeatCount='indefinite' to='360 20 20' type='rotate'/%3E%3C/path%3E%3C/svg%3E%0A");
    }
    &[disabled='true'] {
      &:after {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg enable-background='new 0 0 40 40' version='1.1' viewBox='0 0 40 40' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m20.201 5.169c-8.254 0-14.946 6.692-14.946 14.946 0 8.255 6.692 14.946 14.946 14.946s14.946-6.691 14.946-14.946c-1e-3 -8.254-6.692-14.946-14.946-14.946zm0 26.58c-6.425 0-11.634-5.208-11.634-11.634 0-6.425 5.209-11.634 11.634-11.634s11.633 5.209 11.633 11.634c0 6.426-5.208 11.634-11.633 11.634z' opacity='.2'/%3E%3Cpath transform='rotate(334.914 20 20)' d='m26.013 10.047 1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012v3.312c2.119 0 4.1 0.576 5.812 1.566z'%3E%3CanimateTransform attributeName='transform' attributeType='xml' dur='500ms' from='0 20 20' repeatCount='indefinite' to='360 20 20' type='rotate'/%3E%3C/path%3E%3C/svg%3E%0A");
      }
    }
  }

  &--wrapper {
    display: inline-block;
    text-decoration: none;
  }

  &--disabled {
    opacity: 0.64;
    cursor: not-allowed;
  }

  &--deactivated {
    cursor: initial;
    pointer-events: none;
    &:hover {
      opacity: 1;
    }
  }
}
</style>
