<template>
  <component
    :is="imageType"
    :src="imageSrc"
    class="cwp-icon"
    width="100%"
    :class="classes"
    @click="$emit('click')"
    @keyup.enter="$emit('click')"
  />
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'CwpIcon',
  components: {
    InlineSvg,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    path: {
      type: String,
      default: '',
    },
    interactive: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imageSrc() {
      if (this.path) return this.path
      return `${process.env.VUE_APP_ASSET_PATH}images/icons/${this.name}`
    },
    imageType() {
      return this.imageSrc.includes('.svg') ? 'inline-svg' : 'img'
    },
    classes() {
      return {
        'cwp-link--disabled': this.disabled,
        'cwp-icon--interactive': this.interactive,
      }
    },
  },
}
</script>

<style lang="scss">
.cwp-icon {
  transition: all 500ms ease;
  max-width: 100%;
  height: auto;
  color: currentColor;
  pointer-events: all;

  &--interactive {
    cursor: pointer;

    &:active,
    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }

  &--disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}
</style>
