import Vue from 'vue'
import { Swiper as SwiperClass, Navigation, Scrollbar, Pagination, Autoplay } from 'swiper'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import 'swiper/swiper-bundle.css'

SwiperClass.use([Navigation, Scrollbar, Pagination, Autoplay])

if (process.browser) {
  Vue.use(getAwesomeSwiper(SwiperClass))
}
