<template>
  <div class="cwp-social-links">
    <cwp-link
      v-if="links.facebookUrl"
      class="cwp-social-links__item"
      target="_blank"
      :href="links.facebookUrl"
      label="Follow on Facebook"
      ><cwp-icon interactive name="facebook.svg" alt="Follow on Facebook"
    /></cwp-link>
    <cwp-link
      v-if="links.instagramUrl"
      class="cwp-social-links__item"
      target="_blank"
      :href="links.instagramUrl"
      label="Follow on Instagram"
      ><cwp-icon interactive name="instagram.svg" alt="Follow on Instagram"
    /></cwp-link>
    <cwp-link
      v-if="links.twitterUrl"
      class="cwp-social-links__item"
      target="_blank"
      :href="links.twitterUrl"
      label="Follow on Twitter"
      ><cwp-icon interactive name="twitter.svg" alt="Follow on Twitter"
    /></cwp-link>
    <cwp-link
      v-if="links.linkedInUrl"
      class="cwp-social-links__item"
      target="_blank"
      :href="links.linkedInUrl"
      label="Follow on LinkedIn"
      ><cwp-icon interactive name="linkedin.svg" alt="Follow on LinkedIn"
    /></cwp-link>
    <cwp-link
      v-if="links.youTubeUrl"
      class="cwp-social-links__item"
      target="_blank"
      :href="links.youTubeUrl"
      label="Follow on YouTube"
      ><cwp-icon interactive name="youtube.svg" alt="Follow on YouTube"
    /></cwp-link>
  </div>
</template>

<script>
import CwpLink from '@/components/CwpLink/CwpLink.vue'
import CwpIcon from '@/components/CwpIcon/CwpIcon.vue'
import { mapState } from 'vuex'
export default {
  name: 'CwpSocialLinks',
  components: {
    CwpLink,
    CwpIcon,
  },
  props: {
    contacts: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      integrations: state => state.site.pageSettings.integrations,
    }),
    links() {
      return this.contacts ? this.contacts : this.integrations
    },
  },
}
</script>

<style lang="scss">
.cwp-social-links {
  &__item {
    padding: 12px;

    .cwp-icon {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
