import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store'
import Kentico from '@/api/kentico'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '*',
      components: {
        default: () => import(/* webpackChunkName: "Default" */ './views/Default.vue'),
        Home: () => import(/* webpackChunkName: "Default" */ './views/Default.vue'),
        ContentPage: () => import(/* webpackChunkName: "Default" */ './views/Default.vue'),
        ProjectLandingPage: () => import(/* webpackChunkName: "Default" */ './views/Default.vue'),
        EventDetails: () => import(/* webpackChunkName: "Default" */ './views/Default.vue'),
        NewsArticle: () => import(/* webpackChunkName: "Default" */ './views/Default.vue'),
        NewsCategory: () => import(/* webpackChunkName: "Default" */ './views/Default.vue'),
        StatusCode: () => import(/* webpackChunkName: "StatusCode" */ './views/StatusCode.vue'),
        Loader: () => import(/* webpackChunkName: "Loader" */ './components/CwpLoader/CwpLoader.vue'),
      },
      props: { default: true },
    },
    // Catch absolute urls and redirect the browser
    {
      path: `/*/http*`,
      beforeEnter: to => {
        window.location.href = `http${to.fullPath.split('/http').pop()}`
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash,
        // offset the height of sticky header
        offset: {
          x: 0,
          y: 80,
        },
      }
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// Used to gather info to send to EMS logging
router.afterEach((to, from) => {
  store.dispatch('settings/updateReferrerUrl', `${window.location.origin}${to.fullPath}`)
  store.dispatch('settings/updateCurrentUrl', `${window.location.origin}${from.fullPath}`)

  Vue.prototype.$fbq.track('PageView')

  window.prerenderReady = true
})

router.beforeEach((to, from, next) => {
  // If the same page is requested do nothing
  if (to.path === from.path) return next()

  // Display the loader
  store.dispatch('settings/updatePageType', 'Loader')

  // Before continuing, abort any pending requests for data from kentico
  Kentico.abort()

  // Default fetch
  const getPage = Kentico.getPage(`${to.fullPath}`)
  const getComponents = Kentico.getComponents(`${to.fullPath}`)

  Promise.all([getPage, getComponents])
    .then(values => {
      const page = values[0]
      const components = values[1]
      if (page.status === 200) {
        store.dispatch('site/updatePage', page.data)
        // post tracking data to kentico
        store.dispatch('settings/updateReferrerUrl', `${window.location.origin}${to.fullPath}`)
        store.dispatch('settings/updateCurrentUrl', `${window.location.origin}${from.fullPath}`)

        if (page.data.title) {
          store.dispatch('settings/updateTitle', `${page.data.title}`)

          if (Vue.prototype.$gtm) {
            Vue.prototype.$gtm.trackEvent({
              event: 'pageView',
              pagePath: to.fullPath,
              pageTitle: page.data.title,
            })
          }
        }

        // Ignores the trackPixelEnabled coming from the BE (!!page.data.trackPixelEnabled)
        store.dispatch('settings/updatePagePixelTracking', true)
        store.dispatch('site/updateComponents', components.data)
        store.dispatch('site/updateComponentsHeaders', components.csrf)
        store.dispatch('settings/updateComponentsCode', components.status)

        if (components.status === 200) {
          store.dispatch('settings/updatePageType', page.data.layout?.replace('Revium_', '') ?? 'Home')
        } else {
          store.dispatch('settings/updatePageType', ['StatusCode', page.data.statusCode, page.data.location])
        }
        if (page.data.statusCode === 301 || page.data.statusCode === 302) {
          store.dispatch('settings/updatePageType', ['StatusCode', page.data.statusCode, page.data.location])
        }
      } else if (page.status === 401) {
        let path = page ? page.data.location : null
        if (path) {
          path += `?returnUrl=${to.fullPath}`
          router.push({ path })
        }
      } else if (page.status === 404 || page.status === 500) {
        Kentico.getPageSettings(`${to.path}`).then(settings => {
          store.dispatch('site/updatePageSettings', settings.data)
        })
        store.dispatch('settings/updatePageType', ['StatusCode', page.status])
      }

      if (!store.state.site.componentsReady) store.dispatch('site/updateSetting', { componentsReady: true })
    })
    .catch(() => {
      store.dispatch('settings/updatePageType', ['StatusCode', 500])
    })
    .finally(() => {
      next()
    })
})

export default router
