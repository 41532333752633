import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, configure } from 'vee-validate'
import { required, email, max, min, ext, size, regex, max_value, min_value, numeric } from 'vee-validate/dist/rules'
import { dateTo, dateFrom, dateWeekdaysOnly, compare, requiredIf, date } from './veeValidateRules'

configure({
  mode: 'eager', // aggressive, passive, lazy, eager
})

// import default rules commonly used in forms
// set allowFalse false to allow require checkbox to be ticked
required.params[0].default = false

extend('required', required)
extend('regex', regex)
extend('email', email)
extend('max', max)
extend('min', min)
extend('ext', ext)
extend('size', size)

extend('date', date)
extend('dateTo', dateTo)
extend('dateFrom', dateFrom)
extend('weekdaysOnly', dateWeekdaysOnly)

extend('maxValue', max_value)
extend('minValue', min_value)
extend('numeric', numeric)

// Register provider plugins globally
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

export function buildRules(formName, fields) {
  requiredIf.buildRule(formName, fields)
  compare.buildRule(formName, fields)
}
