/**
 * imageHandler
 * @description takes fully qualified url as a string and appends operations as a query string for kentico image handler
 * @param image: String - string of image
 * @param options: Object - options of operations to append
 * @examples https://imageprocessor.org/imageprocessor-web/imageprocessingmodule/format/#example
 */

const imageHandler = (image, options = {}) => {
  return image
}

export default imageHandler
