import { compare } from '../../functions/utils'
import { extend } from 'vee-validate'

/**
 * buildRule
 * @param {String} formName
 * @param {Array} fields
 * @description loops through condition object insid of fields and creates a rule with the
 * naming schema requiredIf_{formname}_{formfield}
 */
function buildRule(formName, fields) {
  // find all requiredIf objects in form
  let requiredRuleFields = fields.filter(x => x.validation && x.validation.requiredIf)
  requiredRuleFields.forEach(field => {
    let { condition, alternativeFields, groupOperator } = field.validation.requiredIf
    groupOperator = groupOperator || 'and'

    if (!Array.isArray(condition) || condition.length === 0) {
      // delete old objects, will be renamed later
      delete field.validation.requiredIf
      delete field.messages.requiredIf
      return
    }

    if (!Array.isArray(alternativeFields)) {
      alternativeFields = []
    }

    const alternativeTargetFields = alternativeFields.map(x => ({
      name: x,
      isTarget: true,
    }))
    const targetFields = alternativeTargetFields.concat(condition.map(x => ({ name: x.fieldName, isTarget: true })))
    const requiredIfName = `requiredIf_${formName}_${field.name}`
    // create rule for this form
    extend(requiredIfName, {
      computesRequired: true,
      params: [
        { name: 'multiForm', isTarget: true },
        { name: 'condition' },
        { name: 'groupOperator' },
        { name: 'alternativeFields' },
      ].concat(targetFields),
      validate: (value, values) => {
        const { groupOperator, condition, alternativeFields } = values

        let fieldValues = values
        if (
          typeof values.multiForm === 'object' &&
          typeof values.multiForm[formName] === 'object' &&
          typeof values.multiForm[formName].formValues === 'object'
        ) {
          fieldValues = values.multiForm[formName].formValues
        }
        let required = false
        let alternativeFieldsRequired = alternativeFields.every(x => !fieldValues[x])

        switch (groupOperator) {
          case 'and':
            required = condition.every(x => compare(x.comparisonType, fieldValues[x.fieldName], x.value))
            break

          case 'or':
            required = condition.some(x => compare(x.comparisonType, fieldValues[x.fieldName], x.value))
            break
        }

        const rs = {
          valid: true,
          required: required && alternativeFieldsRequired,
        }

        // override message, if value is an array and is empty
        if (Array.isArray(value) && value.length === 0) {
          return field.messages.requiredIf
        }
        // override message, if value is not set or returns empty object
        if (!value && rs.required && field.messages.requiredIf) {
          return field.messages.requiredIf
        }
        return rs
      },
    })

    const requiredIfRule = targetFields.reduce(
      (rule, x) => ({
        [x.name]: x.name,
        ...rule,
      }),
      {
        multiForm: 'multiForm',
        condition,
        groupOperator,
        alternativeFields,
      }
    )

    // rename requiredIf rule to rule per field
    field.validation[requiredIfName] = requiredIfRule
    delete field.validation.requiredIf
  })
}

export default { buildRule }
export { buildRule }
