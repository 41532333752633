<template>
  <div
    :class="value ? 'cwp-search-button--active' : ''"
    class="cwp-search-button"
    tabindex="0"
    @click="$emit('input', !value)"
    @keyup.enter="$emit('input', !value)"
  >
    <div class="cwp-search-button__icon" />
  </div>
</template>

<script>
export default {
  name: 'CwpSearchButton',
  props: {
    value: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
}
</script>

<style lang="scss">
.cwp-search-button {
  position: relative;
  width: 24px;
  height: 24px;
  transition: all 0.3s $easeInOutQuad;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 3px;
    right: 3px;
    width: 0;
    height: 2px;
    background-color: currentColor;
    transform: rotate(-45deg);
    transform-origin: right top;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 3px;
    right: 3px;
    width: 8px;
    height: 2px;
    background-color: currentColor;
    transform: rotate(45deg);
    transform-origin: right bottom;
  }

  &__icon {
    position: absolute;
    display: block;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: currentColor;
  }

  &:before,
  &:after,
  &__icon {
    transition: all 0.3s $easeInOutQuad;
  }

  &--active:before,
  &--active:after {
    width: 24px;
  }
  &--active &__icon {
    width: 0;
    height: 0;
    border-color: transparent;
  }
}
</style>
