import Cookies from 'js-cookie'
import wretchLibrary from 'wretch'
// import { throttlingCache } from 'wretch-middlewares'

/**
 * Kentico class for interacting with api
 * @module Kentico
 */
class Kentico {
  /**
   * Handle all generic methods for retrievntg content and page related options for Kentico
   */
  constructor() {
    this.url = `${process.env.VUE_APP_API_URL}${
      process.env.VUE_APP_DEV_PROJECT ? process.env.VUE_APP_DEV_PROJECT : window.location.pathname.split('/')[1]
    }/mvc/`
    this.defaultHeaders = {
      Accept: 'application/json',
    }

    // Check if its the revium crawler and inject token
    this.insertToken = () => {
      const reviumWebCrawler = new RegExp('ReviumWebCrawler|Chrome', 'ig')
      const urlParams = () => {
        if (new URLSearchParams(window.location.search)) {
          return new URLSearchParams(window.location.search)
        }
      }
      if (reviumWebCrawler.test(navigator.userAgent) && urlParams().getAll('accesstoken').length > 0) {
        this.defaultHeaders.Authorization = `Bearer ${urlParams().getAll('accesstoken')[0]}`
      } else if (Cookies.get('currentJwt')) {
        this.defaultHeaders.Authorization = `Bearer ${Cookies.get('currentJwt')}`
      } else {
        this.defaultHeaders.Authorization = ''
      }
    }

    // set global abort controller for all kentico calls
    this.controller = new AbortController()
    // set default headers and catch err codes to pass to front end
    this.wretch = () =>
      wretchLibrary()
        // .middlewares([throttlingCache()])
        .signal(this.controller)
        .defaults({ headers: this.defaultHeaders })
        .catcher(404, err => ({ status: err.status }))
        .catcher(500, err => ({ status: err.status }))
        .catcher(301, err => ({ status: err.status }))
        .catcher(302, err => ({ status: err.status }))
  }
  /**
   * abort current requests to Kentico
   * @function
   * @name abort
   * @returns new AbortController
   */
  abort() {
    this.controller.abort()
    this.controller = new AbortController()
  }
  /**
   * get page components
   * @function
   * @name getComponents
   * @param {string} node alias path to resource in kentico e.g. '/About-Us'
   * @returns {promise} components
   */
  getComponents(path = '/') {
    return this.wretch()
      .url(
        `${this.url}api/components/current?NodeAliasPath=${path
          .replace('?', '&')
          .replace('/' + window.location.pathname.split('/')[1], '')}`
      )
      .get()
      .res(response => {
        return response.json().then(data => {
          return {
            status: response.status,
            csrf: response.headers.get('X-RequestVerificationToken') ?? '',
            data: data,
          }
        })
      })
  }

  /**
   * get items for listing components on a page
   * @function
   * @name getPaginatedItems
   * @param {string} type post type e.g. news or events
   * @param {string} path alias path to resource in kentico e.g. '/About-Us'
   * @param {number} pageIndex starting index of post to return
   * @param {number} pageSize number of posts to return
   * @param {object} filterParams parameters to filter by
   * @returns {promise}
   */
  getPaginatedItems(type, path = '/', pageIndex = 1, pageSize = 9, filters = {}) {
    let filterParams = new URLSearchParams(filters).toString()
    if (filterParams) filterParams = '&' + filterParams
    return this.wretch()
      .url(
        `${this.url}api/components/${type}?NodeAliasPath=${path
          .replace('?', '&')
          .replace(
            '/' + window.location.pathname.split('/')[1],
            ''
          )}&pageIndex=${pageIndex}&pageSize=${pageSize}${filterParams}`
      )
      .get()
      .json(json => ({
        data: json,
        status: 200,
      }))
  }

  /**
   * get search results
   * @function
   * @name getSearchResults
   * @param {string} querytext text to search for
   * @param {number} pageNumber page to return
   * @param {number} pageSize number of results to return
   * @param {boolean} fuzzyLogic loosely match phrases and include results for similar words
   * @returns {promise}
   */
  getSearchResults(querytext = '', pageNumber = 1, pageSize = 10, fuzzyLogic = true) {
    return this.wretch()
      .url(
        `${this.url}api/search/search?queryText=${querytext}&pageNumber=${pageNumber}&pageSize=${pageSize}&fuzzyLogic=${fuzzyLogic}`
      )
      .get()
      .json(json => ({
        data: json,
        status: 200,
      }))
  }

  /**
   * get documents for document container based on filters
   * @function
   * @name getDocuments
   * @param {string} path alias path to resource in kentico e.g. '/About-Us'
   * @param {object} filters filters for document items
   * @returns {promise}
   */
  getDocuments(path = '/', filters = {}) {
    let filterParams = new URLSearchParams(filters).toString()
    if (filterParams) filterParams = '&' + filterParams
    return this.wretch()
      .url(
        `${this.url}api/components/documents?NodeAliasPath=${path
          .replace('?', '&')
          .replace('/' + window.location.pathname.split('/')[1], '')}${filterParams}`
      )
      .get()
      .json(json => ({
        data: json,
        status: 200,
      }))
  }

  /**
   * page specific seo details
   * @function
   * @name getPage
   * @param {string} node alias path to resource in kentico e.g. '/About-Us'
   * @returns {promise}
   */
  getPage(path = '/') {
    return this.wretch()
      .url(
        `${this.url}api/page/current?NodeAliasPath=${path
          .replace('?', '&')
          .replace('/' + window.location.pathname.split('/')[1], '')}`
      )
      .get()
      .json(json => ({
        data: json,
        status: 200,
      }))
  }
  /**
   * site specific navigation / settings
   * @function
   * @name getPageSettings
   * @returns {promise}
   */
  getPageSettings() {
    return this.wretch()
      .url(`${this.url}api/layout/data`)
      .get()
      .json(json => ({
        data: json,
        status: 200,
      }))
  }
  /**
   * get site search results
   * @function
   * @name getSiteSearchResults
   * @param {string} search string to query api
   * @returns {promise}
   */
  getSiteSearchResults(searchQuery, searchFacet) {
    return this.wretch()
      .url(`${this.url}api/search/search?querytext=${searchQuery}${searchFacet ? `&categoryFacet=${searchFacet}` : ``}`)
      .get()
      .json(json => ({
        data: json,
        status: 200,
      }))
  }
  /**
   * @name getSiteSearchFacets {GET}
   * @success {Object} get available site search facets
   */
  getSiteSearchFacets() {
    return this.wretch()
      .url(`${this.url}api/search/facets`)
      .get()
      .json(json => ({
        data: json,
        status: 200,
      }))
  }
  /**
   * @name getLinkedStyles {GET}
   * @success {Object} Get page data for a linked page
   */
  getLinkedStyles(path) {
    return this.wretch()
      .url(`${process.env.VUE_APP_API_URL}global/mvc/api/page/current?NodeAliasPath=${path.replace('/global/', '/')}`)
      .get()
      .json(json => ({
        data: json,
        status: 200,
      }))
  }
  /**
   * post activity data to kentico to track ems info
   * @function
   * @name postActivity {POST}
   * @success {null}
   */
  postActivity(to, from, title) {
    // dont log requests if developing locally
    if (process.env.VUE_APP_NODE_ENV === 'development') return
    if (to === undefined && from === undefined) {
      return console.warn('missing param "to" and "from"')
    }
    return this.wretch()
      .url(`${this.url}Kentico.Activities/KenticoActivityLogger/Log`)
      .post(
        JSON.stringify({
          title: title,
          url: to,
          referrer: from,
        })
      )
      .json(json => ({
        data: json,
        status: 200,
      }))
  }
}
export default new Kentico()
