import Vue from 'vue'
import Root from './layouts/Root.vue'
import router from './router'
import store from './store/store'

//import '@/plugins/cache'
import '@/plugins/fbq'
import '@/plugins/gtm'
import '@/plugins/swiper'
import '@/plugins/veeValidate'
import '@/plugins/reviumPlugins'
import '@/plugins/meta'
import '@/plugins/lazyLoad'
import '@/plugins/vcalendar'
import '@/plugins/scrollBehaviorPolyfill'
import '@/assets/scss/main.scss'

import VueGtm from 'vue-gtm'
import Kentico from '@/api/kentico'

Vue.config.productionTip = false

async function run() {
  await Kentico.getPageSettings('/').then(settings => {
    store.dispatch('site/updatePageSettings', settings.data)
    store.dispatch('settings/updatePageSettingsCode', settings.status)
    store.dispatch('settings/updateIntegrations', settings.data.integrations)

    Vue.prototype.$fbq.init(settings.data.integrations.facebookPixelSnippet)

    const { gtmContainerId, corpGtmContainerId } = settings?.data?.integrations || {}

    const checkGtmIdIsValid = gtmId => {
      if (gtmId && /^GTM-[A-Z0-9]{1,8}$/.test(gtmId)) {
        return true
      }
      process.env.NODE_ENV !== 'production' && console.warn(`GTM ID: ${gtmId} is invalid`)
      return false
    }

    const gtmIds = [
      ...(checkGtmIdIsValid(gtmContainerId) ? [gtmContainerId] : []),
      ...(checkGtmIdIsValid(corpGtmContainerId) ? [corpGtmContainerId] : []),
    ]

    if (gtmIds.length) {
      try {
        Vue.prototype.$gtmTrack.init(VueGtm, gtmIds)
      } catch {
        console.warn('[VueGtm] - Invalid container ID.')
      }
    }
  })

  new Vue({
    router,
    store,
    render: h => h(Root),
  }).$mount('#app')
}

run()
