var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{attrs:{"to":_vm.to},scopedSlots:_vm._u([{key:"default",fn:function({ navigate, href: rlHref }){return [_c(_vm.tag,_vm._g({tag:"component",staticClass:"cwp-link",class:_vm.classes,attrs:{"tabindex":"0","role":"button","target":_vm.target,"aria-label":_vm.label,"href":_vm.external || _vm.attachment || _vm.isButtonTag ? _vm.to : _vm.href || rlHref,"download":_vm.download || _vm.attachment},on:{"click":ev => {
        if (_vm.download || _vm.attachment) {
          _vm.$fbq.track('PDF Download')
        }
        if (_vm.external || _vm.attachment || _vm.href || _vm.disabled || _vm.deactivated || _vm.isButtonTag) return
        navigate(ev)
      }}},_vm.onEvent),[_vm._t("default")],2)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }